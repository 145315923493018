@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900');

:root {
  --plyr-color-main: #9333ea;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  @apply transition-all duration-200;
}

body {
  @apply overflow-hidden bg-[#0f172a];
  font-family: 'Nunito', sans-serif;
}

.folder-title {
  @apply border-b border-gray-500 pt-5 text-base font-bold text-gray-400;
}
.folder-container {
  @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5;
}

.form-input {
  @apply mt-4 flex w-11/12 flex-col;
}

.form-input label {
  @apply px-2 text-lg  text-gray-400;
}

.form-input input {
  @apply w-full rounded-lg border-2 border-purple-500 bg-transparent py-1 px-2 text-gray-500 outline-none placeholder:text-slate-600 focus:placeholder:opacity-0;
}

.button-icon {
  @apply m-1 rounded-lg border-[3px] border-gray-500 p-1 text-gray-500 shadow-md;
}

/*  */
/*  */
/*  */
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  @apply bg-slate-800;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-slate-700;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @apply bg-slate-600;
}

input:-webkit-autofill {
  border: 3px solid blue;
}
input:autofill {
  border: 3px solid blue;
}
